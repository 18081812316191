<template>
  <v-container>
    <GoBackBtn :routeToGo="routeToGo" />
    <PageHeader :title="title" />
    <v-card class="mx-auto my-12 mt-2 mb-2" max-width="1200" outlined>
      <v-container>
        <v-row>
          <v-col cols="10" class="py-0" align="left">
            <FiltersSelected :filters="filtersApplied" v-if="!showFilters" />
          </v-col>
          <v-col cols="2" align="right" class="py-1">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="primary--text"
                  @click.stop="showFilters = !showFilters"
                >
                  {{ closeFiltersIcon }}
                </v-icon>
              </template>
              <span>{{
                !showFilters ? "Mostrar filtros" : "Ocultar filtros"
              }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-form
            v-show="showFilters"
            v-model="isFormValid"
            ref="filters-form"
            id="filters-form"
            @submit.prevent="applyFilters()"
          >
            <!-- Filtros comunes -->
            <v-row dense>
              <!-- Codigo agente -->
              <v-col cols="4" class="pt-0 px-1" md="2">
                <v-text-field
                  onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                  outlined
                  hide-details="true"
                  dense
                  clearable
                  v-mask="'#########'"
                  label="Código agente"
                  v-model="codigoAgenteCta"
                  @change="buscarAgente"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pt-0 px-1" md="4">
                <v-text-field
                  type="text"
                  outlined
                  dense
                  clearable
                  hide-details="true"
                  disabled
                  label="Nombre de agente de cuenta"
                  v-model="nombreAgenteCta"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <!-- Boton buscar por nombre o dni -->
              <v-col cols="1" class="pt-0 px-1">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      absolute
                      fab
                      small
                      color="primary"
                      :disabled="searchAgenteBtn"
                      @click="modalBusquedaAgecta = true"
                    >
                      <v-icon>{{ searchIcon }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Buscar agente de cuenta</span>
                </v-tooltip>
              </v-col>

              <!-- Convenio -->
              <v-col cols="3" sm="4" md="4" class="pt-0 px-0 ml-5">
                <v-select
                  outlined
                  hide-details="true"
                  clearable
                  dense
                  item-text="value"
                  item-value="id"
                  multiple
                  return-object
                  v-model="convenioSelected"
                  label="Convenios"
                  :items="convenios"
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="conveniosToggle()">
                      <v-list-item-action>
                        <v-icon
                          :color="convenioSelected.length > 0 ? 'primary' : ''"
                        >
                          {{ multiselectIconConvenios }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title> Todos </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.value }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text caption">
                      (+{{ convenioSelected.length - 1 }} otros)
                    </span>
                  </template>
                </v-select>
              </v-col>
              <!-- solo autorizaciones facturadas -->
              <v-col cols="4" sm="4" md="3" class="py-0 px-1 mr-5">
                <v-switch
                  v-model="autorizacionesFacturadas"
                  label="Solo autorizaciones facturadas"
                  item-text="value"
                  item-value="id"
                  hide-details="true"
                  @change="
                    autorizacionesFacturadas === false
                      ? ((activoExpediente = false), resetNumExp())
                      : ''
                  "
                  dense
                  outlined
                ></v-switch>
              </v-col>
              <!-- Expediente -->
              <v-col cols="4" sm="6" md="2" class="py-0 px-1">
                <v-switch
                  hide-details="true"
                  v-model="activoExpediente"
                  label="En expediente N°:"
                  dense
                  @change="resetNumExp()"
                  outlined
                  :disabled="autorizacionesFacturadas == false"
                >
                </v-switch>
              </v-col>
              <!-- Número de expediente -->
              <v-col cols="4" sm="4" md="2" class="pl-1 pr-15">
                <v-text-field
                  type="text"
                  outlined
                  hide-details="true"
                  dense
                  :disabled="
                    activoExpediente === false ||
                      autorizacionesFacturadas === false
                  "
                  :rules="[rules.maxLength(numeroExpediente, 50)]"
                  v-model="numeroExpediente"
                  v-mask="'######'"
                  ref="numExp"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="1" class="px-0"></v-col> -->
              <v-col cols="6" md="2" class="px-0">
                <!-- fecha consulta desde -->
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fechaDesdeSelected"
                      label="Fecha desde"
                      :append-icon="calendarIcon"
                      v-mask="'##/##/####'"
                      hide-details="true"
                      hint="formato DD/MM/AAAA"
                      @keydown.tab="menu1 = false"
                      onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                      clearable
                      dense
                      outlined
                      autocomplete="off"
                      :rules="
                        rules.required.concat(
                          rules.validDate,
                          new Date(parseDateToIso(fechaDesdeSelected)) <=
                            new Date(parseDateToIso(fechaHastaSelected)) ||
                            'Formato incorrecto'
                        )
                      "
                      v-bind="attrs"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>

                  <v-date-picker
                    v-model="fechaDesde"
                    no-title
                    scrollable
                    @change="[(fechaDesdeSelected = formatDate(fechaDesde))]"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" md="2" class="pl-1 pr-0">
                <!-- fecha consulta hasta -->
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fechaHastaSelected"
                      label="Fecha hasta"
                      :append-icon="calendarIcon"
                      hide-details="true"
                      @keydown.tab="menu2 = false"
                      v-mask="'##/##/####'"
                      onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                      hint="formato DD/MM/AAAA"
                      clearable
                      dense
                      @blur="
                        [(fechaHasta = parseDateToIso(fechaHastaSelected))]
                      "
                      @change="
                        [(fechaHasta = parseDateToIso(fechaHastaSelected))]
                      "
                      :rules="
                        rules.required.concat(
                          rules.validDate,
                          new Date(parseDateToIso(fechaDesdeSelected)) <=
                            new Date(parseDateToIso(fechaHastaSelected)) ||
                            'Formato incorrecto'
                        )
                      "
                      outlined
                      autocomplete="off"
                      v-bind="attrs"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>

                  <v-date-picker
                    v-model="fechaHasta"
                    no-title
                    scrollable
                    @change="[(fechaHastaSelected = formatDate(fechaHasta))]"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <!-- Origen -->
              <v-col cols="12" sm="6" md="3" class="pb-0 pl-1 pr-0 mr-5">
                <v-autocomplete
                  ref="origen-autocomplete"
                  v-model="origenSelected"
                  hide-details="true"
                  :items="origenesSearchResult"
                  :search-input.sync="origenesAutocompleteInput"
                  :disabled="agrupacionSelected != null"
                  outlined
                  item-text="value"
                  @change="
                    agrupacionSelected = null;
                    grupoSelected = null;
                  "
                  item-value="id"
                  dense
                  clearable
                  return-object
                  autocomplete="off"
                  label="Origen"
                >
                  <template slot="item" slot-scope="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.value }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <template v-slot:append>
                    <v-progress-circular
                      indeterminate
                      size="28"
                      v-if="origenesLoading"
                      color="primary"
                    ></v-progress-circular>
                  </template>
                </v-autocomplete>
              </v-col>
              <!-- Agrupacion -->
              <v-col cols="4" class="pb-0 px-1">
                <v-autocomplete
                  v-model="agrupacionSelected"
                  :items="itemsAgrupacion"
                  label="Agrupación"
                  item-text="value"
                  :disabled="origenSelected != null"
                  hide-details="true"
                  item-value="id"
                  outlined
                  @change="setGruposByAgrupaciones"
                  clearable
                  dense
                  return-object
                >
                </v-autocomplete>
              </v-col>
              <!-- Grupo -->
              <v-col cols="4" class="pb-0 pl-0 pr-0">
                <v-autocomplete
                  class="p-0"
                  v-model="grupoSelected"
                  :items="itemsGrupo"
                  :disabled="origenSelected != null"
                  hide-details="true"
                  label="Grupo"
                  :rules="agrupacionSelected != null ? rules.required : []"
                  item-text="value"
                  item-value="id"
                  outlined
                  return-object
                  clearable
                  dense
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="pr-4 pl-4 pt-1" justify="end">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    size="28"
                    @click="resetForm"
                  >
                    {{ clearFiltersIcon }}
                  </v-icon>
                </template>
                <span>Limpiar filtros</span>
              </v-tooltip>
              <v-btn
                color="primary"
                elevation="2"
                small
                :disabled="!isFormValid"
                type="submit"
                form="filters-form"
              >
                Aplicar
              </v-btn>
            </v-row>
          </v-form>
        </v-expand-transition>
      </v-container>
    </v-card>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="nuevoProcesoDeDevengamiento"
        :loading="isLoading"
        class="elevation-1"
        item-key="encAutoId"
        show-select
        v-model="registrosSeleccionados"
        :search="search"
      >
        <template>
          <v-icon v-if="registrosSeleccionados.length > 0">
            mdi-checkbox-marked</v-icon
          >
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="4" md="6" sm="2">
                <v-text-field
                  v-model="search"
                  :append-icon="searchIcon"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="3" md="3" sm="2" align="end" class="pr-1">
                <v-spacer></v-spacer>
                <v-btn
                  small
                  :disabled="registrosSeleccionados.length == 0"
                  color="primary"
                  @click="openModalGenerarConcepto()"
                  >Generar concepto a devengar</v-btn
                >
              </v-col>
              <v-col cols="3" align="end">
                <v-btn
                  small
                  color="primary"
                  class="to-right"
                  :disabled="registrosSeleccionados.length == 0"
                  @click="openModalGenerarComprobante(registrosSeleccionados)"
                  >Generar comprobantes</v-btn
                >
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="16"
                v-on="on"
                @click="
                  openModalVerAutorizacion(item.encAutoId, item.numeroOrden)
                "
              >
                {{ seeIcon }}
              </v-icon>
            </template>
            <span>Ver autorización</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-card-actions>
      <v-row class="mt-2">
        <v-col cols="12" md="3" class="p-1">
          <v-text-field
            outlined
            dense
            disabled
            filled
            label="Importe total"
            v-model="importeTotal"
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="3" class="text-right p-1">
          <v-tooltip top max-width="35%">
            <template v-slot:activator="{ attrs }">
              <v-btn
                color="primary"
                class="fontsize"
                v-bind="attrs"
                outlined
                dense
                :disabled="nuevoProcesoDeDevengamiento.length == 0"
                @click="exportExcelModeloLista"
              >
                Exportar lista completa
              </v-btn>
            </template>
          </v-tooltip>
        </v-col>
        <v-col cols="12" md="4" class="text-right p-1" align="end">
          <v-tooltip top max-width="40%">
            <template v-slot:activator="{ attrs }">
              <v-btn
                color="primary"
                class="fontsize"
                v-bind="attrs"
                outlined
                dense
                :disabled="nuevoProcesoDeDevengamiento.length == 0"
                @click="exportExcelAutorizacionsSinAgte"
              >
                Exportar autorizaciones sin agte. de cta.
              </v-btn>
            </template>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-actions>

    <v-dialog
      v-model="modalBusquedaAgecta"
      v-if="modalBusquedaAgecta"
      @keydown.esc="toggleModalBusquedaAgecta"
      max-width="65%"
      persistent
    >
      <BuscarAgenteCuenta
        @toggleModalBusquedaAgecta="toggleModalBusquedaAgecta"
        @findAgenteCta="findAgenteCta"
      ></BuscarAgenteCuenta>
    </v-dialog>
    <v-dialog
      v-model="modalPracticasCoseguro"
      v-if="modalPracticasCoseguro"
      @keydown.esc="toggleModalPracticasCoseguro"
      max-width="65%"
      persistent
    >
      <PracticasNewCoseguro
        @toggleModalPracticasCoseguro="toggleModalPracticasCoseguro"
        @findAgenteCta="findAgenteCta"
      ></PracticasNewCoseguro>
    </v-dialog>
    <v-dialog
      v-model="modalVerAutorizacion"
      v-if="modalVerAutorizacion"
      @keydown.esc="closeModalVerAutorizacion"
      max-width="70%"
      persistent
    >
      <VerAutorizacionCoseguros
        :nuevoDevengId="nuevoDevengId"
        :numeroOrden="numeroOrden"
        @closeModalVerAutorizacion="closeModalVerAutorizacion"
      ></VerAutorizacionCoseguros>
    </v-dialog>
    <v-dialog
      v-model="modalGenerarConcepto"
      v-if="modalGenerarConcepto"
      @keydown.esc="closeModalGenerarConcepto"
      max-width="65%"
      persistent
    >
      <GenerarConceptoADevengar
        :registrosSeleccionados="registrosSeleccionados"
        :fechaDesde="fechaDesde"
        :fechaHasta="fechaHasta"
        :idAgente="idAgente"
        :filtrosAplicados="filtrosAplicados"
        @closeModalGenerarConcepto="closeModalGenerarConcepto"
        @closeModalGenerarConceptoAndReload="closeModalGenerarConceptoAndReload"
      ></GenerarConceptoADevengar>
    </v-dialog>
    <v-dialog
      v-model="modalGenerarComprobante"
      v-if="modalGenerarComprobante"
      @keydown.esc="closeModalGenerarComprobante"
      max-width="65%"
      persistent
    >
      <GenerarComprobanteCtaCte
        :registrosSeleccionados="registrosSeleccionados"
        :fechaDesde="fechaDesde"
        :fechaHasta="fechaHasta"
        :filtrosAplicados="filtrosAplicados"
        :idAgente="idAgente"
        @closeModalGenerarComprobante="closeModalGenerarComprobante"
        @closeModalGenerarComprobanteAndReload="
          closeModalGenerarComprobanteAndReload
        "
      ></GenerarComprobanteCtaCte>
    </v-dialog>
    <DeleteDialog
      :maxWidth="'25%'"
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmQuitarComprobante()"
    />
    <ConfirmDialog
      :text="textConfirmDialog"
      :openConfirm.sync="openConfirmDialog"
      @onConfirm="exportExcelAutorizacionsSinAgte(autorizacionesSinAgente)"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import getFormatPrice from "@/utils/helpers/getFormatPrice";
import FiltersSelected from "@/components/shared/FiltersSelected";
import DeleteDialog from "@/components/shared/DeleteDialog";
import GoBackBtn from "@/components/shared/GoBackBtn";
import BuscarAgenteCuenta from "@/components/modules/cuotas/agenteCuenta/BuscarAgenteCuenta.vue";
import PracticasNewCoseguro from "@/components/modules/cuotas/devengamientos/PracticasNewCoseguro.vue";
import VerAutorizacionCoseguros from "@/components/modules/cuotas/devengamientos/VerAutorizacionCoseguros.vue";
import GenerarConceptoADevengar from "@/components/modules/cuotas/devengamientos/GenerarConceptoADevengar.vue";
import GenerarComprobanteCtaCte from "@/components/modules/cuotas/devengamientos/GenerarComprobanteCtaCte.vue";
import { mask } from "vue-the-mask";
import helpersExcel from "@/utils/helpers/importExportExcel";

export default {
  name: "NuevoDevengCoseguro",
  directives: { mask },
  components: {
    PageHeader,
    FiltersSelected,
    GoBackBtn,
    BuscarAgenteCuenta,
    VerAutorizacionCoseguros,
    PracticasNewCoseguro,
    DeleteDialog,
    ConfirmDialog,
    numeroOrden: null,
    GenerarConceptoADevengar,
    GenerarComprobanteCtaCte
  },
  data: vm => ({
    title: enums.titles.NUEVO_PROCESO_DEVENG_COSEGURO,
    seeIcon: enums.icons.SEE,
    checkbox: enums.icons.CHECKBOX_BLANK_OUTLINE,
    expanded: [],
    codigoAgenteCta: null,
    activoConvenio: false,
    activoExpediente: false,
    numeroExpediente: undefined,
    modalBusquedaAgecta: false,

    modalPracticasCoseguro: false,
    openConfirmDialog: false,
    textConfirmDialog: "",
    nombreAgenteCta: null,
    searchAgenteBtn: false,
    objectFechas: {},
    titleDelete: "¿Eliminar registro?",
    registrosSeleccionados: [],
    showDeleteModal: false,
    importeTotal: 0,
    filtersApplied: [],
    disabledCount: 0,
    routeToGo: "DevengamientoCosegurosWeb",
    convenioSelected: [],
    convenios: [],
    origenesSearchResult: [],
    menu1: false,
    menu2: false,
    fechaDesde: null,
    filtrosAplicados: null,
    fechaHasta: null,
    fechaDesdeSelected: null,
    fechaHastaSelected: null,
    isLoading: false,
    nuevoProcesoDeDevengamiento: [],
    search: "",
    filtersSeleccionados: {},
    searchIcon: enums.icons.SEARCH,
    headers: [
      {
        text: "N° Orden",
        value: "numeroOrden",
        align: "start",
        sortable: false
      },
      {
        text: "Fecha de realización",
        value: "fecRea",
        align: "start",
        sortable: false
      },
      {
        text: "Agente de cuenta",
        value: "agectaNom",
        align: "start",
        sortable: false
      },
      {
        text: "Origen",
        value: "oriNom",
        align: "start",
        sortable: false
      },
      {
        text: "N° afiliado",
        value: "benNumero",
        align: "start",
        sortable: false
      },
      {
        text: "Afiliado",
        value: "benNom",
        align: "start",
        sortable: false
      },
      {
        text: "Coseguro neto",
        value: "coseNetoString",
        align: "end",
        sortable: false
      },
      {
        text: "Coseguro IVA",
        value: "coseIvaString",
        align: "end",
        sortable: false
      },
      {
        text: "Coseguro total ",
        value: "coseTotalString",
        align: "end",
        sortable: false
      },

      { text: "Acciones", value: "actions", sortable: false, align: "center" }
    ],
    origenesSearchResult: [],
    origenesLoading: false,
    origenSelected: null,
    origenesAutocompleteInput: null,
    modalVerAutorizacion: false,
    modalGenerarConcepto: false,
    modalGenerarComprobante: false,
    filtersApplied: [],
    calendarIcon: enums.icons.CALENDAR,
    checkIcon: enums.icons.CHECK_OUTLINE,
    showFilters: true,
    showFiltersSelected: false,
    isFormValid: true,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    deleteIcon: enums.icons.DELETE,
    rules: rules,
    idAgente: null,
    periodoDesde: null,
    periodoHasta: null,
    autorizacionesFacturadas: false,
    nuevoProcLista: "",
    autorizacionesSinAgente: [],
    exportarPracticasAExcel: [],
    agrupacionSelected: null,
    itemsAgrupacion: [],
    itemsGrupo: [],
    grupoSelected: null
  }),

  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setSelects();
    this.getFechas();
  },
  computed: {
    multiselectIconConvenios() {
      if (this.selectAllConvenios) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    selectAllConvenios() {
      return (
        this.convenioSelected &&
        this.convenioSelected.length === this.convenios.length
      );
    }
  },
  watch: {
    registrosSeleccionados() {
      if (this.registrosSeleccionados.length > 0) {
        this.importeTotal = this.registrosSeleccionados
          .map(x => x.coseTotal)
          .reduce((a, b) => a + b, 0);
        this.importeTotal = this.addDecimals(this.importeTotal);
      } else {
        this.importeTotal = 0;
      }
    },
    origenesAutocompleteInput(val) {
      if (val && val.length >= 3) {
        this.getOrigenesFilter();
      }
    }
  },
  methods: {
    ...mapActions({
      nuevoProcesoCoseguroListaSeleccion:
        "configuracion/nuevoProcesoCoseguroListaSeleccion",
      getTiposDeComprobantes: "devengamientos/getTiposDeComprobantes",
      getConveniosByVigencia: "afiliaciones/getConveniosByVigencia",
      getAgenteCuentaByCodigo: "afiliaciones/getAgenteCuentaByCodigo",
      fetchOrigenesByQuery: "prestadores/fetchOrigenesByQuery",
      getAgrupaciones: "prestadores/getAgrupaciones",
      getGruposByAgrupaciones: "prestadores/getGruposByAgrupaciones",
      setAlert: "user/setAlert"
    }),
    async setSelects() {
      const res = await this.getConveniosByVigencia({
        fechaAlta: this.parseDateToIso(this.hoyFecha())
      });
      this.convenios = res;
      const agrupaciones = await this.getAgrupaciones();
      this.itemsAgrupacion = agrupaciones;
    },
    resetNumExp() {
      this.$refs["numExp"].reset();
    },
    getFechas() {
      let now = new Date();
      let año = now.getFullYear();
      let mes = now.getMonth() + 1;
      let dia = now.getDate();
      dia < 10 ? (dia = `0${dia}`) : dia;
      mes < 10 ? (mes = `0${mes}`) : mes;
      let one = new Date(now.setMonth(now.getMonth() - 1));
      let oneAño = one.getFullYear();
      let oneMes = one.getMonth() + 1;
      let oneDia = one.getDate();
      oneDia < 10 ? (oneDia = `0${oneDia}`) : oneDia;
      oneMes < 10 ? (oneMes = `0${oneMes}`) : oneMes;
      this.fechaDesde = `${oneAño}-${oneMes}-${oneDia}`;
      this.fechaDesdeSelected = this.formatDate(this.fechaDesde);
      this.fechaHasta = `${año}-${mes}-${dia}`;
      this.fechaHastaSelected = this.formatDate(this.fechaHasta);
    },
    exportExcelModeloLista() {
      let result = [];
      this.exportarPracticasAExcel?.forEach(x =>
        result.push({
          ["N° de Orden"]: x.numeroOrden,
          ["Fecha de rea."]: x.fecRea,
          ["Origen"]: x.oriNom,
          ["N° de afiliado"]: x.benNumero,
          ["Afiliado"]: x.benNom,
          ["Coseguro Neto"]: x.coseNetoString,
          ["Coseguro IVA"]: x.coseIvaString,
          ["Coseguro total"]: x.coseTotalString,
          ["Observación del coseguro"]:
            "Es el coseguro TOTAL de la autorización.",
          ["Agte. cta. id"]: x.agectaId,
          ["Agte. cta. nombre"]: x.agectaNom,
          ["Matrícula"]: x.datosPracticas.prestadorMatricula,
          ["Tipo prestador"]: "Clínicas, Sanatorios y Hospitales",
          ["Prestador"]: x.datosPracticas.prestadorNom,
          ["Tipo nomenclador"]: "Prestaciones Médicas",
          ["Código"]: x.datosPracticas.nomencladorCodigo,
          ["Práctica"]: x.datosPracticas.practica,
          ["Cantidad"]: x.datosPracticas.cantidad
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado lista"
      };
      helpersExcel.excelExport(formato, "Lista nuevo deveng. coseguro");
    },
    exportExcelAutorizacionsSinAgte() {
      let result = [];
      this.autorizacionesSinAgente?.forEach(x =>
        result.push({
          ["N° de Orden"]: x.numeroOrden,
          ["Fecha de rea."]: x.fecRea,
          ["Orígen"]: x.oriNom,
          ["N° de afiliado"]: x.benNumero,
          ["Afiliado"]: x.benNom,
          ["Coseguro Neto"]: x.coseNetoString
        })
      );
      let formato = {
        filas: result,
        hoja: "Autorización"
      };
      helpersExcel.excelExport(formato, "Autorización sin agente de cta.");
    },
    getOrigenesFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        if (
          this.origenesSearchResult?.some(
            x => x.value == this.origenesAutocompleteInput
          )
        ) {
          return;
        } else if (!this.banderaOrigenSelected) {
          this.origenesLoading = true;
          const response = await this.fetchOrigenesByQuery({
            input: this.origenesAutocompleteInput
          });
          this.$refs["origen-autocomplete"].cachedItems = [];
          this.origenesSearchResult = response;
          this.origenesLoading = false;
        }
      }, 1000);
    },
    async setGruposByAgrupaciones() {
      this.grupoSelected = this.origenSelected = null;
      if (this.agrupacionSelected) {
        const grupos = await this.getGruposByAgrupaciones(
          this.agrupacionSelected.id
        );
        this.itemsGrupo = grupos;
      } else this.itemsGrupo = [];
    },
    findAgenteCta(agenteSelected) {
      this.codigoAgenteCta = agenteSelected.agenteId.toString();
      this.nombreAgenteCta = agenteSelected.nombre;
    },
    async buscarAgente() {
      if (this.codigoAgenteCta != null) {
        const search = await this.searchAgenteCta(this.codigoAgenteCta);
        this.findAgenteCta(search);
      } else {
        this.nombreAgenteCta = null;
      }
    },
    async searchAgenteCta(codigoAgte) {
      {
        const response = await this.getAgenteCuentaByCodigo({
          codigo: codigoAgte,
          nombre: "",
          dni: "",
          cuit: ""
        });
        const agenteResp = response[0];
        if (agenteResp) {
          return agenteResp;
        }
      }
    },
    toggleModalBusquedaAgecta() {
      this.modalBusquedaAgecta = !this.modalBusquedaAgecta;
    },
    toggleModalPracticasCoseguro() {
      this.modalPracticasCoseguro = !this.modalPracticasCoseguro;
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    hoyFecha() {
      let now = new Date();
      let day = ("0" + now.getDate()).slice(-2);
      let month = ("0" + (now.getMonth() + 1)).slice(-2);
      let today = day + "/" + month + "/" + now.getFullYear();

      return today;
    },
    addDecimals(x) {
      return getFormatPrice(x);
    },
    async applyFilters() {
      (this.showFilters = false), (this.isLoading = true);
      this.customizeFiltersApplied();
      const data = {
        fechaDesde: this.fechaDesdeSelected,
        fechaHasta: this.fechaHastaSelected,
        agectaId: this.codigoAgenteCta,
        facturado: this.autorizacionesFacturadas ? 1 : 0,
        conConvenio: this.convenioSelected.length > 0 ? 1 : 0,
        convenios: this.convenioSelected.map(x => x.id),
        expId: this.numeroExpediente,
        oriId: this.origenSelected != null ? this.origenSelected.id : 0,
        grupoOriId: this.grupoSelected != null ? this.grupoSelected.id : 0
      };
      this.nuevoProcLista = await this.nuevoProcesoCoseguroListaSeleccion(data);
      this.nuevoProcesoDeDevengamiento = this.nuevoProcLista.procesosCoseguros;
      this.registrosSeleccionados = this.nuevoProcLista.procesosCoseguros;
      this.autorizacionesSinAgente = this.nuevoProcLista.registrosSinAgeCta;
      this.exportarPracticasAExcel = this.nuevoProcLista.practicasAExcel;
      if (this.nuevoProcLista.registrosSinAgeCta.length > 0) {
        this.openConfirmDialog = true;
        this.textConfirmDialog =
          "Existen autorizaciones sin agente de cuenta, ¿desea exportar archivo?";
      }
      this.filtrosAplicados = `Agente de cuenta: ${this.nombreAgenteCta ??
        "Sin dato."}; Convenios: ${
        this.convenioSelected.length > 0
          ? this.convenioSelected.map(x => x.value)
          : "Sin dato."
      }; Solo autorizaciones facturadas: ${
        this.autorizacionesFacturadas ? "Sí." : "No."
      }; En expediente N°: ${this.numeroExpediente ??
        "Sin dato."}; Fecha desde: ${this.fechaDesdeSelected}; Fecha hasta: ${
        this.fechaHastaSelected
      }; Origen: ${this.origenSelected?.value ??
        "Sin dato."}; Grupo de orígenes: ${this.grupoSelected?.value ??
        "Sin dato."};`;
      this.isLoading = false;
    },
    openModalVerAutorizacion(nuevoDevengId, numeroOrden) {
      this.modalVerAutorizacion = true;
      this.nuevoDevengId = nuevoDevengId;
      this.numeroOrden = numeroOrden;
    },
    closeModalVerAutorizacion() {
      this.modalVerAutorizacion = false;
    },
    openModalGenerarConcepto() {
      this.idAgente = parseInt(this.codigoAgenteCta);
      this.fechaDesde = this.parseDateToIso(this.fechaDesdeSelected);
      this.fechaHasta = this.parseDateToIso(this.fechaHastaSelected);
      this.modalGenerarConcepto = true;
    },
    closeModalGenerarConcepto() {
      this.modalGenerarConcepto = false;
    },
    closeModalGenerarConceptoAndReload() {
      this.modalGenerarConcepto = false;
      this.applyFilters();
      this.importeTotal = 0;
      this.registrosSeleccionados = [];
      this.openConfirmDialog = false;
    },
    openModalGenerarComprobante() {
      const comprobantesInvalidos = this.registrosSeleccionados.find(
        item => item.coseCtaCteId == 0
      );
      if (comprobantesInvalidos != null) {
        this.setAlert({
          type: "warning",
          message:
            "Hay comprobantes que no pueden devengarse, por falta de configuración"
        });
      }
      this.idAgente = parseInt(this.codigoAgenteCta);
      this.fechaDesde = this.parseDateToIso(this.fechaDesdeSelected);
      this.fechaHasta = this.parseDateToIso(this.fechaHastaSelected);
      this.modalGenerarComprobante = true;
    },
    closeModalGenerarComprobante() {
      this.modalGenerarComprobante = false;
    },
    closeModalGenerarComprobanteAndReload() {
      this.applyFilters();
      this.modalGenerarComprobante = false;
      this.importeTotal = 0;
      this.registrosSeleccionados = [];
      this.openConfirmDialog = false;
    },
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.codigoAgenteCta) {
        this.filtersApplied.splice(0, 0, {
          key: "codigoAgenteCta",
          label: "Código agente cta.",
          model: this.codigoAgenteCta
        });
      }
      if (this.nombreAgenteCta) {
        this.filtersApplied.splice(1, 0, {
          key: "nombreAgenteCta",
          label: "Nombre agente cta.",
          model: this.nombreAgenteCta
        });
      }
      if (this.convenioSelected) {
        this.filtersApplied.splice(2, 0, {
          key: "conConvenio",
          label: "Con convenio",
          model: this.convenioSelected
        });
      }
      if (this.autorizacionesFacturadas) {
        const facturadas = "SI";
        this.filtersApplied.splice(3, 0, {
          key: "facturado",
          label: "Autorizaciones facturadas",
          model: facturadas
        });
      } else {
        const facturadas = "NO";
        this.filtersApplied.splice(3, 0, {
          key: "facturado",
          label: "Autorizaciones facturadas",
          model: facturadas
        });
      }
      if (this.numeroExpediente) {
        this.filtersApplied.splice(4, 0, {
          key: "numeroExpediente",
          label: "Número de expediente",
          model: this.numeroExpediente
        });
      }
      if (this.fechaDesdeSelected) {
        this.filtersApplied.splice(5, 0, {
          key: "fechaDesde",
          label: "Fecha desde",
          model: this.fechaDesdeSelected
        });
      }
      if (this.fechaHastaSelected) {
        this.filtersApplied.splice(6, 0, {
          key: "fechaHasta",
          label: "Fecha hasta",
          model: this.fechaHastaSelected
        });
      }
      if (this.origenSelected) {
        this.filtersApplied.splice(7, 0, {
          key: "origenSelected",
          label: "Origen",
          model: this.origenSelected.value
        });
      }
      if (this.agrupacionSelected) {
        this.filtersApplied.splice(8, 0, {
          key: "agrupacionSelected",
          label: "Agrupación de orígenes",
          model: this.agrupacionSelected.value
        });
      }
      if (this.grupoSelected) {
        this.filtersApplied.splice(9, 0, {
          key: "grupoSelected",
          label: "Grupo de orígenes",
          model: this.grupoSelected.value
        });
      }
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    conveniosToggle() {
      this.$nextTick(() => {
        if (this.selectAllConvenios) {
          this.convenioSelected = [];
        } else {
          this.convenioSelected = this.convenios;
        }
      });
    }
  }
};
</script>

<style scoped>
.no-upper-case {
  text-transform: none;
}
.fontsize {
  font-size: 12px;
}
</style>
